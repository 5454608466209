import './login.css'
import React, { useState, useEffect } from 'react';
import { Button, Form, Input, message, Checkbox } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import tasteCubeAPI from "../../api/tasteCube";
import { getkcard } from '../../utils/common/common';

const Login = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);

    const onFinish = async () => {
        const jsonData = {
            Account: username,
            Password: password,
            type: "account"
        }
        const storeid = getkcard(window.location.search, 'storeid');
        const response = await tasteCubeAPI.userLogin(jsonData);
        if (response.statusCode === 200) {
            message.success('登录成功！');
            sessionStorage.setItem('userToken', JSON.stringify(response.data));
            sessionStorage.setItem('userAccount', JSON.stringify(jsonData));
            if (rememberMe) {
                localStorage.setItem('username', username);
                localStorage.setItem('password', password);
                localStorage.setItem('rememberMe', 'true');
            } else {
                localStorage.removeItem('username');
                localStorage.removeItem('password');
                localStorage.removeItem('rememberMe');
            }
            storeid ? navigate('/bpa?storeid=' + storeid) : navigate('/bpa');
        } else {
            message.error(response.errors);
        }
    };

    useEffect(() => {
        document.title = '代客下单系统登录'
        const savedUsername = localStorage.getItem('username');
        const savedPassword = localStorage.getItem('password');
        const savedRememberMe = localStorage.getItem('rememberMe') === 'true';
        if (savedUsername && savedPassword && savedRememberMe) {
            form.setFieldValue('username', savedUsername);
            setUsername(savedUsername);
            form.setFieldValue('password', savedPassword);
            setPassword(savedPassword);
            setRememberMe(savedRememberMe);
        }
        console.log(form.getFieldValue('username', savedUsername));
    }, []);

    return (
        <div className="login-container">
            <div className='login-head'>
                <div className='login-company-logo'>
                    <img src="https://witt.black-pa.com/logo.png" alt="黑菠萝科技.png" />
                </div>
                <div className='company-name'>
                    黑菠萝科技代客下单系统
                </div>

            </div>
            <div className='login-card'>
                <Form
                    form={form}
                    name="normal_login"
                    className="login-form"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: '用户名是必填项',
                            },
                        ]}
                    >
                        <Input
                            style={{ borderBottom: '1px solid #DEDEDE', padding: '0.8rem 0' }}
                            bordered={false}
                            value='333'
                            allowClear={true}
                            size="large"
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            placeholder="请输入用户名"
                            onChange={(e) => {
                                setUsername(e.target.value);
                                console.log('aaaaa', e.target.value)
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: '密码是必填项',
                            },
                        ]}
                    >
                        <Input.Password
                            style={{ borderBottom: '1px solid #DEDEDE', padding: '0.8rem 0' }}
                            bordered={false}
                            value={password}
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="请输入密码"
                            size="large"
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Checkbox
                            checked={rememberMe}
                            onChange={(e) => setRememberMe(e.target.checked)}
                        >
                            记住密码
                        </Checkbox>
                    </Form.Item>
                    <Form.Item >
                        <div className='login-btn-card'>
                            <Button style={{ width: '100%' }} size="large" block={true} type="primary" htmlType="submit" className="login-form-button">
                                登录
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>

        </div >
    )
}

export default Login;